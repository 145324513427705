import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import "../sass/styles.scss"
import logo from "../images/aou-logo.svg"
import hero from "../images/hero-banner@2x.jpg"
import map from '../images/awardees-map.jpg';
import CircleIcon from "../components/circle-icon"
// import Slider from "react-slick"
// import { AnchorLink } from "gatsby-plugin-anchor-links"
// import Image from 'react-bootstrap/Image';

const IndexPage = () => {
  return (
    <main>
      <Container fluid className="py-3">
        <img id="main-logo" src={logo} alt="All of Us: Researcher Academy" width="250" />
      </Container>

      {/* Mobile Hero */}
      <div className="d-sm-none">
        <img src={hero} alt="Female Researcher"  className="img-fluid"/>
        <div className="bg-white p-4 shadow-lg mx-5" style={{ position: 'relative', top: '-15px' }}>
          <h1 className="hero-title">About the <em>All of Us</em> Research Program</h1>
          <p className="mb-0 hero-text">
            The National Institutes of Health’s <em>All of Us</em> Research Program is a historic effort to speed up health research discoveries, enabling new kinds of individualized health care. To make this possible, the program is building one of the world’s largest and most diverse databases for health research. <i>All of Us</i> aims to include a million or more people living in the United States and its territories. The program seeks for its participant cohort to reflect the diversity of the U.S. population and to include individuals from groups that have been underrepresented in health research in the past.
          </p>
        </div>
      </div>
      {/* <Container fluid className="d-sm-none">
        <Row>
          <Col>
            <Image src={hero} fluid />
          </Col>
        </Row>
        <Row style={{ position: 'relative', top: '-15px' }}>
          <Col className="px-5">
           <div className="bg-white p-4 shadow-lg">
                <h1 className="hero-title">About the <em>All of Us</em> Research Program</h1>

                <p className="mb-0 hero-text">
                  The National Institutes of Health’s <a target="_blank" rel="noreferrer" href="https://allofus.nih.gov/"><em>All of Us</em> Research Program</a> is a historic effort to collect and study data from at least one million people living in the United States. The goal of All of Us is to speed up health research discoveries, enabling new kinds of individualized health care. To make this possible, the program is building one of the world’s largest and most diverse databases for health research. The program aims to reflect the diversity of the United States and to include participants from communities that have been underrepresented in health research in the past.
                </p>
              </div>
          </Col>
        </Row>
      </Container> */}

      {/* Desktop Hero */}
      <div className="banner-img d-flex align-items-center d-none d-sm-flex">
        <Container>
          <Row className="py-5 my-sm-0 my-md-5 my-xl-0">
            <Col xl={{ span: 5, offset: 6 }} className="">
              <div className="bg-white p-4">
                <h1 className="hero-title">About the <em>All of Us</em> Research Program</h1>

                <p className="mb-0 hero-text">
                  The National Institutes of Health’s <a target="_blank" rel="noreferrer" href="https://allofus.nih.gov/"><em>All of Us</em> Research Program</a> is a historic effort to speed up health research discoveries, enabling new kinds of individualized health care. To make this possible, the program is building one of the world’s largest and most diverse databases for health research. <i>All of Us</i> aims to include a million or more people living in the United States and its territories. The program seeks for its participant cohort to reflect the diversity of the U.S. population and to include individuals from groups that have been underrepresented in health research in the past.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="py-5 my-lg-5">
        <Row className="gy-4">
          <Col lg={6}>
            <h2 className="">About the <i>All of Us</i> Researcher Academy</h2>
            <p>
              The <i>All of Us</i> Researcher Academy provides training and technical assistance for researchers who are conducting research with the <a target="_blank" rel="noreferrer" href="https://www.researchallofus.org/register/?utm_source=RTI&utm_medium=researcher_events&utm_campaign=grassroots&utm_content=academy_sm"><i>All of Us</i> Researcher Workbench</a>—the cloud-based platform where registered researchers can access data contributed by <a target="_blank" rel="noreferrer" href="https://www.researchallofus.org/data-tools/workbench/?_gl=1*xn6fge*_ga*MTg4MDM1NS4xNzI0MTA1NjE5*_ga_K8QTQT89XP*MTcyNDE4NDk2NS40LjEuMTcyNDE4NjY1Ni41MC4wLjA."><i>All of Us</i> participants</a>. The academy also supports peer-to-peer learning and network-building among researchers and students.
            </p>
            <p>
              The academy is dedicated to providing support to institutions that have a documented historical mission or historical commitment to training underrepresented students. See the <a target="_blank" rel="noreferrer" href="https://grants.nih.gov/grants/guide/notice-files/NOT-OD-20-031.html">Notice of NIH's Interest in Diversity</a> for examples of groups that have been shown to be underrepresented in the biomedical research workforce.
            </p>
            <p>
              <i>All of Us</i> Researcher Academy resources are provided at no cost to students, faculty, and post-docs at institutions that have received academy Institutional Champion awards. RTI International has supported research teams at 19 universities overall.
            </p>
            <p>
              RTI International leads the <i>All of Us</i> Researcher Academy in collaboration with the <i>All of Us</i> Research Program’s Division of Engagement and Outreach. The division partners with community organizations nationwide to foster relationships with participants, researchers, and health care providers. The academy and other researcher engagement activities are central to building a diverse community of researchers.
            </p>
          </Col>
          <Col lg={{ span: 5, offset: 1 }} className="d-flex flex-column gap-4">
            <div className="p-4 card-bg">
              <h3 className="mb-3"><em>Connections</em> Newsletter</h3>
              <ul>
                <li>The Fall 2024 issue is live! <a target="_blank" rel="noreferrer" href="https://aou-files.s3.us-east-1.amazonaws.com/academy/AoU_RA__Connections9Fall24.pdf">Read it here.</a></li>
                <li><a target="_blank" rel="noreferrer" href="https://researcheracademy.rti.org/newsletter">Read past issues here.</a></li>
                <li><a target="_blank" rel="noreferrer" href="https://lp.constantcontactpages.com/su/2b3nzej/SignUp?__cf_chl_tk=EqzS8Z0FJP5orulVxB_5G34q_vkNoCt1vZRChBlRBUE-1724186531-0.0.1.1-5417">Subscribe to <em>Connections</em> today</a> to receive academy updates, course announcements, researcher spotlights, career guidance, and news about upcoming opportunities and events.</li>
              </ul>
            </div>
            <div className="p-4 card-bg">
              <h3 className="mb-3">Get Involved with the Researcher Academy</h3>
              <div>
                <h6>Funding Opportunities—<em>All of Us</em> Data and Tools</h6>
                <ul>
                  <li>
                    The National Institutes of Health makes funding available to researchers to advance precision medicine and health equity. These opportunities can harness the power of the <span style={{'display':'inline-block'}}><em>All of Us</em></span> Researcher Workbench. <a target="_blank" rel="noreferrer" href="https://allofus.nih.gov/funding-and-program-partners/funding-opportunities/nih-funding-opportunities-researchers">Visit the Funding Opportunities page</a> for additional information.
                  </li>
                </ul>
              </div>
            </div>

            <div>
              <img src={map} className="img-fluid" alt="All of Us Institutional Champions Map" />
            </div>
          </Col>
        </Row>
      </Container>
      <div className="courseOfferingsBand py-5">
        <Container className="my-lg-5">
          <Row className="gx-lg-5">
            <Col lg={6}>
              <h3 className="mb-3">Past Webinars</h3>

              <ul className="d-flex flex-column gap-2">
                <li>
                  <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=U6shzP_tMlA"><i>All of Us</i> Researcher Academy HBCU Road Tour at North Carolina A&T State University (June 2024)</a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=_2nJs44ZJEk"><i>All of Us</i> Researcher Academy HBCU Road Tour at Jackson State University (April 2024)</a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=R3bdDjHawmU"><i>All of Us</i> Researcher Academy HBCU Road Tour at Fisk University and Meharry Medical College (February 2024)</a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=LL6T_9Fn40w"><i>All of Us</i> Researcher Academy HBCU Road Tour at Morehouse School of Medicine (September 2023)</a>
                </li>
                <li>
                   <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=JMLa0Fp3XrY"><i>All of Us</i> Researcher Academy HBCU Road Tour at Tuskegee University (April 2023)</a>
                </li>
                <li>
                   <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=p_MPQZvT9RM"><i>All of Us</i> Researcher Academy Celebration of the First Cohort of Institutional Champion Awardees/HBCU Road Tour at Howard University (January 2023)</a>
                </li>
                <li>
                   <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=SkYJThyOYeo">Introducing the <i>All of Us</i> Researcher Academy (June 2022)</a>
                </li>
              </ul>
            </Col>
            <Col lg={6}>
              <h3 className="mb-3">Press Releases</h3>

              <ul className="d-flex flex-column gap-2">
                <li>
                  <a target="_blank" rel="noreferrer" href="https://www.rti.org/announcements/all-us-researcher-academy-names-third-institutional-champions"><i>All of Us</i> Researcher Academy Names Third Group of Institutional Champions</a>
                </li>
                <li>
                   <a target="_blank" rel="noreferrer" href="https://www.rti.org/announcements/all-us-researcher-academy-selects-second-group-institutional-champions"><i>All of Us</i> Researcher Academy Selects Second Cohort of Institutional Champions</a>
                </li>
                <li>
                   <a target="_blank" rel="noreferrer" href="https://www.rti.org/announcements/nih-all-of-us-research-academy-and-minority-serving-institutions">Introducing the NIH-Supported <em>All of Us</em> Researcher Academy</a>
                </li>
                <li>
                   <a target="_blank" rel="noreferrer" href="https://www.rti.org/news/all-us-researcher-academy-selects-6-hbcus-institutional-champions"><i>All of Us</i> Researcher Academy Selects 6 HBCUs as Institutional Champions</a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="py-5 my-lg-5">
       <Row>
        <Col lg={12} className="d-flex flex-column align-items-center gap-4">
         <CircleIcon
            name="icon-laptop"
            circleColor="#F0F1FF"
            circleSize="152"
            iconSize="135"
            top="30px"
            left="8px"
          />
          <div className="text-center">
            <div className="question-title mb-3">Questions about the <i>All of Us</i> Researcher Academy?</div>
            <p className="question-text">
              Contact <a href="mailto:AllofUs_Academy@rti.org">AllofUs_Academy@rti.org</a> to learn how you can participate.
            </p>
          </div>
        </Col>
       </Row>
      </Container>
      <footer>
        <div className="mainFooter py-5">
          <Container>
            <Row className="mb-5">
              {/* <Col className="d-lg-flex justify-content-between"> */}
              <Col className="footerLogos d-flex justify-content-start flex-column flex-sm-row gap-4 gap-lg-5">
                <div className="mr-auto">
                  
                    <img
                      src="/images/aou_ralogo.png"
                      alt="All of Us: Researcher Academy Logo"
                      width="169"
                    />
                 
                </div>
                <div>
                  <a target="_blank" rel="noreferrer" href="https://rti.org">
                    <img src="/images/rti_logo.svg" alt="RTI Logo" />
                  </a>
                </div>
                <div>
                  <a target="_blank" rel="noreferrer" href="https://ccphealth.org">
                    <img
                      src="/images/community-campus-logo@2x.png"
                      alt="Community Campus Partnerships for Health Logo"
                      width="187"
                    />
                  </a>
                </div>
                {/* <div>
                  <a target="_blank" rel="noreferrer" href="https://tracs.unc.edu">
                    <img
                      src="/images/unc-logo.svg"
                      alt="University of North Carolina (UNC) School of Medicine North Carolina Transitional and Clinical Sciences Institute"
                    />
                  </a>
                </div> */}
              </Col>
            </Row>
            <Row>
              <Col lg={5}>
                <p>
                 This platform is supported by RTI International. The Researcher Academy is funded by the Division of Engagement and Outreach, <em>All of Us</em> Research Program, National Institutes of Health, under Award Number OT2OD028395.
                </p>
                <p>
                 <em>All of Us</em> and the <em>All of Us</em> logo are registered service marks of the <a target="_blank" rel="noreferrer" href="https://www.hhs.gov">U.S. Department of Health and Human Services</a>. The <em>All of Us</em> platform is for research only and does not provide medical advice, diagnosis, or treatment.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="subFooter py-3">
          <Container>
            <Row>
              <Col className="d-block d-sm-flex">
                <div className="mb-1 mb-sm-0">Copyright © {new Date().getFullYear()}</div>
                <div className="mx-0 mx-sm-4 mb-3 mb-sm-0">
                  <a target="_blank" rel="noreferrer" href="https://www.researchallofus.org/privacy-policy/">Privacy Policy</a>
                </div>
                <div className="footerExtraLinks">
                  <a className="d-block d-sm-inline mb-1 mb-sm-0" href="https://www.joinallofus.org/" target="_blank" rel="noreferrer">JoinAllOfUs.org</a>
                  <a className="d-block d-sm-inline mb-1 mb-sm-0" href="https://researchallofus.org/" target="_blank" rel="noreferrer">ResearchAllofUs.org</a>
                  <a className="d-block d-sm-inline" href="https://allofus.nih.gov/" target="_blank" rel="noreferrer">AllofUs.NIH.gov</a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    </main>
  )
}

export default IndexPage

export const Head = () => <title>Home Page</title>
